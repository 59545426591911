import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

import ResponsiveContainer from '../layout/ResponsiveContainer';
import { ViewportBreakpoints } from '../cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import Button from '@rotaready/frecl/build/Button';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ShadowBox = styled.div`
  text-align: center;
  padding: 40px;
  border-radius: 3px;
  box-shadow: 0 0 8px 2px ${({ theme: { colors } }) => colors.grey20};
  border: solid 1px ${({ theme: { colors } }) => colors.grey20};
  background: ${({ theme: { colors } }) => colors.white};
  
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 90%;
    padding: 50px 0;
  }
`;

const ButtonBox = styled.div`
  margin-top: 40px;
  padding: 0 40px;
  
  button {
    width: 100%;
    margin: 0 0 20px 0;
  }
  
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 30px;
    padding: 0;
    
    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const InnerComponent = () => (
  <StaticQuery
    query={graphql`
      query {
        bg: file(relativePath: { eq: "misc/cta-breaker.png" }) {
          childImageSharp {
            fixed(height: 369, width: 991, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.bg.childImageSharp.fixed;

      return (
        <BackgroundImage fixed={imageData} style={{ overflow: 'visible' }}>
          <Wrapper>
            <ShadowBox>
              <H2 uistyle="brand160" text="Like what you've seen already?" />

              <ButtonBox>
                <Link to="/demo">
                  <Button uistyle="primary" text="Request demo" size="lg" />
                </Link>
                <Link to="/contact">
                  <Button uistyle="primary" text="Speak to the team" size="lg" ghost borderless />
                </Link>
              </ButtonBox>
            </ShadowBox>
          </Wrapper>
        </BackgroundImage>
      );
    }}
  />
);

const StyledInnerComponent = styled(InnerComponent)`
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 991px 369px;
`;

const OuterComponent = () => (
  <ResponsiveContainer>
    <Wrapper>
      <StyledInnerComponent />
    </Wrapper>
  </ResponsiveContainer>
);

export default OuterComponent;
